import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../flaticon/flaticon.css";
import "./style.css";
import img_poster from "../../img/Cover-Portrait.jpg";

const Subscribe = () => {
  return (
    <>
      <section className="subscribe-section section_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="subscribe-box">
                <div className="section-title">
                  <h2>
                    Available <span>Now</span>
                  </h2>
                  <p>on Oculus Applab</p>
                </div>
                <div className="poster-image">
                  <img src={img_poster} alt="Poster Image" className="poster-image" />
                </div>
                <div className="button-container">
                  <a href="https://ocul.us/3BkRtzM" className="theme-btn2" type="button" target="_blank" rel="noopener">
                    Play Now
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Subscribe;
