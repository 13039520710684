import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import "../../flaticon/flaticon.css";
import img1 from "../../img/about-post-01.jpg";
import img2 from "../../img/about-post-02.jpg";
import img3 from "../../img/about-post-03.jpg";
import shape1 from "../../img/about-shape.png";
import "./style.css";

const AboutPages = () => {
  return (
    <>
      <section className="about-page-section section_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title ">
                <h2>
                  Play<span>Fun</span>VR<br />
                 </h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={{ span: 4, offset: 1 }}>
              <div className="about-page-left ">
                <h2 className="mr-5">
                  <span className="mark-text">5</span>Years’ Experience in IT
                </h2>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-text ">
                <p>
                We a VR gaming company with over 5 years of experience in creating immersive gaming experiences. Our passion for gaming drives us to push the boundaries of what's possible in VR, delivering cutting-edge games that transport players to new worlds and challenge them in exciting ways.
                </p>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-post-section section_b_70">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <div className="single-about-post ">
                <div className="about-post-image">
                  <img src={img1} alt="img" />
                </div>
                <div className="about-post-text">
                  <h3>fun to play</h3>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="single-about-post  ">
                <div className="about-post-image">
                  <img src={img2} alt="img" />
                </div>
                <div className="about-post-text">
                  <h3>strive to create games</h3>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="single-about-post  ">
                <div className="about-post-image">
                  <img src={img3} alt="img" />
                </div>
                <div className="about-post-text">
                  <h3>Quality first</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="timeline-area section_100">
        <div className="about-top-shape">
          <img src={shape1} alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src={shape1} alt="about shape" />
        </div>
        <Container>
          <Row>
            <Col md={12}>
              <div className="ui-timline-container">
                <div className="ui-timeline">
                  <div className="tl-item">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-caption">
                          <p>
                            Our <br /> Journey
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tl-item  ">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-time">2023</div>
                        <div className="tl-icon" />
                        <div className="tl-content">
                          <h3 className="tl-tile">First Game</h3>
                          <h4 className="tl-date">March 26</h4>
                          <p>
                           Today we are proud to say that we have reached our first milestone - a potential VR game is in active development.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tl-item alt  ">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-time">2022</div>
                        <div className="tl-icon" />
                        <div className="tl-content">
                          <h3 className="tl-tile">Experience Gained</h3>
                          <h4 className="tl-date">February 19</h4>
                          <p>
                          Countless hours of development, experimenting with gameplay mechanics, graphics, and controls until solid level experience reached.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tl-item  ">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-time">2020</div>
                        <div className="tl-icon" />
                        <div className="tl-content">
                          <h3 className="tl-tile">PlayFunVR was founded</h3>
                          <h4 className="tl-date">April 04</h4>
                          <p>
                          Was established this day by a group of developers who saw the potential of VR gaming.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutPages;
