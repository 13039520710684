import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../flaticon/flaticon.css";
import "./style.css";

const Privacy = () => {
  return (
    <>
      <section className="privacy-section section_t_50 section_b_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="privacy-inn">
                <div className="privacy-block">
                  <h3>Privacy Policy</h3>
                  <p>We at PlayFun VR respect your privacy and are committed to protecting it through our compliance with this Privacy Policy. </p>
                  <p>This Privacy Policy explains who we are, how we process personal information about you, and how you can exercise your privacy rights. We are PlayFun VR, a company under laws of Republic of Lithuania, with registered address at: 219, Ukmergės g., LT-07152 Vilnius, Lithuania (“PlayFun VR” or “we”), a developer and publisher of vritual reality games and virtual reality game applications that are made available via app stores including the Meta Store and Oculus Store owned by Meta Inc. This Privacy Policy applies to personal information that we collect through our website, games, mobile applications and other products (“Games”) (together “Services”). By using our Services, you agree to be bound by this Privacy Policy and consent to our processing of information as specified therein.</p>

                </div>
                <div className="privacy-block">
                  <h3>Information We Collect</h3>
                  <p>
                    We may collect the following information from users:</p>
                  <ul>
                    <li>
                      Personal Information: We may collect personal information such as name, email address, and other contact information from users who register for our applications, services, and games.
                    </li>
                    <li>
                      Usage Information: We may collect usage information such as IP address, device type, operating system, and other technical information when users access our applications, services, and games.
                    </li>
                    <li>
                      In-Game Analytics: We may collect in-game analytics such as retention, sessions, installs, playtime, DAU, MAU, and other in-game information when users access our applications, services, and games.
                    </li>
                  </ul>

                </div>
                <div className="privacy-block">
                  <h3>How We Use Your Information</h3>
                  <ul>
                    <li>
                      To provide and improve our applications, services, and games: We use the information to analyze user behavior and improve our applications, services, and games.
                    </li>
                    <li>
                      To communicate with users: We may use the information to communicate with users about their accounts, subscriptions, or to respond to their inquiries.
                    </li>
                    <li>
                      To comply with legal obligations: We may use the information to comply with applicable laws, regulations, or legal processes.
                    </li>
                  </ul>
                </div>
                <div className="privacy-block">
                  <h3>Disclosure of Information</h3>
                  <p>We do not share user information with any third parties except in the following circumstances:</p>
                  <ul>
                    <li>
                      With user consent: We may share user information with third parties if users have given their explicit consent to such sharing.
                    </li>
                    <li>
                      With service providers: We may share user information with third-party service providers who assist us in providing our applications, services, and games. These service providers are contractually bound to keep user information confidential and secure.
                    </li>
                    <li>
                      For legal reasons: We may share user information if required to do so by law or in response to a legal request.
                    </li>
                  </ul>
                </div>
                <div className="privacy-block">
                  <h3>Data Retention </h3>
                  <p>
                    We retain user information for as long as necessary to fulfill the purposes described in this Privacy Policy or as required by law. However, if a user wishes to opt-out of data collection or request the deletion of their data, they can do so by contacting us via email at <span className="blue-text">media@playfunvr.com</span>. We will delete all information related to the user promptly.
                  </p>
                </div>
                <div className="privacy-block">
                  <h3>Security </h3>
                  <p>
                    We take reasonable measures to protect user information from unauthorized access, use, disclosure, or destruction. However, no security measures can guarantee absolute protection of user information.
                  </p>
                </div>
                <div className="privacy-block">
                  <h3>Opt-Out</h3>
                  <p>
                    If you no longer wish to receive communications from us, you may opt-out by emailing us at <span className="blue-text">media@playfunvr.com</span> for more information. We will promptly remove you from all future communications.
                  </p>
                </div>
                <div className="privacy-block">
                  <h3>Data Deletion</h3>
                  <p>
                    You may request that we delete any personal data we hold about you by emailing us at <span className="blue-text">media@playfunvr.com</span>
                    We will verify your identity before deleting your personal data. Once your identity has been verified, we will delete all personal data we hold about you unless we are required by law to retain it.
                  </p>
                </div>
                <div className="privacy-block">
                  <h3>Changes to this Privacy Policy</h3>
                  <p>
                    We may update this Privacy Policy from time to time by posting a new version on our Website. Your continued use of our  “Website”, “Services” or “Games” after we make changes is deemed to be acceptance of those changes.
                  </p>
                </div>
                <div className="privacy-block">
                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions or concerns about this Privacy Policy, please contact us at <span className="blue-text">media@playfunvr.com</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Privacy;
