import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import img0 from "../../img/about-shape.png";
import img1 from "../../img/screenshot01.jpg";
import img2 from "../../img/screenshot02.jpg";
import img3 from "../../img/screenshot03.jpg";
import img4 from "../../img/screenshot04.jpg";
import img5 from "../../img/screenshot05.jpg";
import img6 from "../../img/screenshot06.jpg";

import "./style.css";

const About = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-top-shape">
          <img src={img0} alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src={img0} alt="about shape" />
        </div>
        <div className="about-top section_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="about-left" data-aos="fade-right">
                  <h3>
                    Explore Hexaverse Adventures
                  </h3>
                  <p>
                    Hexaverse Adventures is a VR game that takes players on an immersive journey through an unexplored planet. As the player, you must gather resources by chopping trees, mining rocks, and harvesting other materials to build planet piece by piece and transform the planet.</p>
                  <a href="https://ocul.us/3BkRtzM" className="theme-btn mt-4" target="_blank" rel="noopener noreferrer">
                    Play Now
                    <span>
                      <FaChevronRight />
                    </span>
                  </a>


                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="about-right" data-aos="fade-left">
                  <img src={img1} alt="about" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-bottom section_b_100">
        <Container>
  <Row className="align-items-center">
    <Col lg={4} md={4}>
      <div className="about-right" data-aos="fade-right">
        <img src={img2} alt="about" />
      </div>
    </Col>
    <Col lg={4} md={4}>
      <div className="about-right" data-aos="fade-right">
        <img src={img3} alt="about" />
      </div>
    </Col>
    <Col lg={4} md={4}>
      <div className="about-right" data-aos="fade-right">
        <img src={img4} alt="about" />
      </div>
    </Col>
  </Row>
  <Row className="align-items-center">
    <Col lg={4} md={4}>
      <div className="about-right" data-aos="fade-right">
        <img src={img5} alt="about" />
      </div>
    </Col>
    <Col lg={4} md={4}>
      <div className="about-right" data-aos="fade-right">
        <img src={img6} alt="about" />
      </div>
    </Col>
    <Col lg={4} md={4}>
      <div className="about-right" data-aos="fade-right">
        <img src={img6} alt="about" />
      </div>
    </Col>
  </Row>
  </Container>

        </div>
      </section>
      
    </>
  );
};

export default About;
