import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../img/logo.png";
import "../../flaticon/flaticon.css";
import "./style.css";
import {
  FaFacebookF,
  FaGooglePlus,
  FaLinkedinIn,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const Cta = () => {
  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <footer className="footer-section">
        {/* Footer Top Start */}
        <div className="footer-top">
          <Container>
            <Row>
              <Col lg={4} md={6}>
                <div className="single-footer ">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                  <p>
                    UAB "PlayFun VR"{" "}
                  </p>
                  <ul className="footer-social">
                    <li>

                      <a href="https://twitter.com/PlayfunVr6082" target="_blank" rel="noopener noreferrer" className="tw">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.facebook.com/playfunvr" target="_blank" rel="noopener noreferrer" className="tw">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.youtube.com/channel/UCjgsbPpiXeyn14tdLxDSnfQ" target="_blank" rel="noopener noreferrer" className="tw">
                        <FaYoutube />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.tiktok.com/@playfunvr" target="_blank" rel="noopener noreferrer" className="tw">
                        <FaTiktok />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" className="tw">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={5} md={6}>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="single-footer  ">
                      <h3>Navigation</h3>
                      <ul>
                        <li>
                        <a href="/about">About Us</a>
                        </li>
                        <li>
                        <a href="/team">Our Games</a>
                        </li>
                        <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </Col>

                </Row>
              </Col>
              <Col lg={3} md={6}>
                <div className="single-footer ">
                  <h3>Contact Us</h3>
                  <ul className="footer-contact-info">
                    <li>
                      <span>Email:</span>
                      <a href="mailto:hello@example.com">media@playfunvr.com</a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Footer Top End */}
        {/* Footer Bottom Start */}
        <div className="footer-bottom">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="copyright ">
                  <p>
                    &copy; 2023 PlayFun VR
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Footer Bottom End */}
      </footer>
    </>
  );
};

export default Cta;
