import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaLink,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import teamImg1 from "../../img/team-1.jpg";
import teamImg2 from "../../img/team-2.jpg";
import "./style.css";

const Team = () => {
  return (
    <>
      <section className="team-section section_t_100 section_b_70">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title  ">
                <h2>
                  Our<span> Games</span> <br />
                  {" "}
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            {/* Team Block Start */}
            <Col lg={4} md={6}>
              <div className="team-item  ">
                <div className="image">
                  <img src={teamImg1} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://ocul.us/3BkRtzM" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Hexaverse Adventures</h3>
                    <span>Action</span>
                  </div>
                </div>
              </div>
            </Col>
            {/* Team Block End */}
            {/* Team Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item ">
                <div className="image">
                  <img src={teamImg2} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Untitled</h3>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Block End */}
            {/* Team Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item  ">
                <div className="image">
                  <img src={teamImg2} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Untitled</h3>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Block End */}
            {/* Team Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item ">
                <div className="image">
                  <img src={teamImg2} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Untitled</h3>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Block End */}
            {/* Team Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item  ">
                <div className="image">
                  <img src={teamImg2} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Untitled</h3>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Block End */}
            {/* Team Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item ">
                <div className="image">
                  <img src={teamImg2} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Untitled</h3>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Block End */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Team;
